import React from 'react'
import banner from './image/contact-banner.webp';
import { Navbar } from './Navbar'
import { Footer } from './Footer'
import { Helmet } from 'react-helmet'
import pdf1 from './image/EC Letter (Jujhar).pdf';
import pdf2 from './image/EC Letter Vigilant Media.pdf';
import pdf3 from './image/Jujhar SMC 31.03.2024.pdf';

export const EVC_Documents = () => {
  return (
    <>
        <Helmet>
    <title>Jujhar Group - EC Documents</title>
      <meta name="description" content="Sales query India: +91-161-4044444, Email: corporate@jujhargroup.com, Address: Level 5, Grand Walk Ferozepur Road, Ludhiana, Punjab 141012"/>
    </Helmet>
        <Navbar/>
        {/* <section id='banner' className='p-0 contactbanner'>
        <div className='banner' style={{ backgroundImage: `url(${banner})` }}>
            
        </div>
    </section> */}
    <section className="evc-document">
        <div className='overview'>
            <div className="container">
                <div className="web-container">
                    <div className="col-12 row text-center m-0">
                      <div className="col-md-12 col-xs-12">
                        <div className="heading" data-aos="fade-left" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                          <h1>EC  <span><em>Documents</em></span></h1>
                        </div>
                      </div>
                      <div className="col-md-12 col-xs-12">
                        <div className="aboutcont" data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">
                            <a href={pdf1} className=' btn-3 m-2' download="EC Letter (Jujhar)"><span> Download EC Letter (Jujhar) </span></a>
                            <a href={pdf2} className=' btn-3 m-2' download="EC Letter Vigilant Media"><span> Download EC Letter Vigilant Media </span></a>
                            <a href={pdf3} className=' btn-3 m-2' download="Jujhar SMC 31-03-2024"><span> Download Jujhar SMC 31.03.2024 </span></a>
                        
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}
