import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export const Number = () => {
      const [state, setState] = useState({val1:1, val2:1, val3:1, val4:1, val5:1, val6:1, val7:1, val8:1, val9:1, val10:1});
          return (
            <>
               <section id='numbers'>
                 
       
                <div className="numbers">
                    <div className="container">
                        <div className="row">
                        <div className='title'>
            <h2 data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true">OUR SUCCESS IN NUMBERS</h2>
        </div>          
                  </div>
                    </div>
                       <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
                    <div className='numberbanner col-12'>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex flex-wrap">
                            {/* <div className="App">
            <div className="countnum">
             {isVisible ? <CountUp start={(state.val1===1) ? 1 : 1200} end={1200} duration={3} onEnd={()=> setState({val1:1200})} /> : <CountUp start={1} end={1200} duration={0} />}+
        <p>crore</p>
        <div className='labelcont'>
         turnover
        </div>
            </div>     
            
      </div> */}
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.val2===1) ? 1 : 4500} end={4500} duration={2} onEnd={()=> setState({val2:4500})} /> : <CountUp start={1} end={4500} duration={0} />} +                
             <div className='labelcont'>
         workforce
        </div>
            </div>     
            
      </div>
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.val3===1) ? 1 : 400} end={400} duration={2} onEnd={()=> setState({val3:400})} /> : <CountUp start={1} end={400} duration={0}/>} +     
            <p>cities</p>
             <div className='labelcont'>8 states
        </div>
            </div>     
            
      </div>
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.val4===1) ? 1 : 5} end={5} duration={3} onEnd={()=> setState({val4:5})} /> : <CountUp start={1} end={5} duration={0} />} +
             <p className='space'> million</p>
             <div className='labelcont'>
              digital cable subscribers
             </div>
            </div>     
            
      </div>
      <div className="App">
            <div className="countnum">
            <div className="countnum">
             {isVisible ? <CountUp start={(state.workforce===1) ? 1 : 1} end={7} duration={2} separator=" "  onEnd={()=> setState({ workforce:7 })} /> : <CountUp start={1} end={7} duration={0} />} +             
        <p className='space'> lakhs</p>
        <div className='labelcont'>
              ISP subscribers 
             </div>
            </div>
            </div>     
            
      </div>
      <div className="App">
            <div className="countnum">
                  <div className='countwrap'>
            {isVisible ? <CountUp start={(state.val5===1) ? 1 : 280000} end={280000} duration={3} onEnd={()=> setState({val5:280000})} /> : <CountUp start={1} end={280000} duration={0} />} +
             <p>km</p>
             </div>
             <div className='labelcont'>
             of Extensive Fiber Network
             </div>
            </div>
            
      </div>
      <div className="App">
      <div className="countnum">
             {isVisible ? <CountUp start={(state.val7===1) ? 1 : 132} end={132} duration={2} onEnd={()=> setState({val7:132})} /> : <CountUp start={1} end={132} duration={0} />} +                  
        <p>buses</p>
        <div className='labelcont'>
              volvo and mercedes 
             </div>
            </div>
      </div>
      <div className="App">
      <div className="countnum">
             {isVisible ? <CountUp start={(state.val7===1) ? 1 : 320} end={320} duration={2} onEnd={()=> setState({val7:320})} /> : <CountUp start={1} end={320} duration={0} />} + 
        <div className='labelcont'>
             car carriers
             </div>
            </div>
      </div>
      <div className="App">
      <div className="countnum">
             {isVisible ? <CountUp start={(state.val8===1) ? 1 : 350} end={350} duration={2} onEnd={()=> setState({val8:350})} /> : <CountUp start={1} end={350} duration={0} />} +                  
        <p>acres</p>
        <div className='labelcont'>
              integrated township development 
             </div>
            </div>
            
      </div>
      <div className="App">
      <div className="countnum">
             {isVisible ? <CountUp start={(state.val8===1) ? 1 : 400} end={400} duration={2} onEnd={()=> setState({val8:400})} /> : <CountUp start={1} end={400} duration={0} />} +                  
        <p>Seater</p>
        <div className='labelcont'>
              In-house call centre
             </div>
            </div>
            
      </div>
      <div className="App">
      <div className="countnum">
              <h3>No.</h3>
             {isVisible ? <CountUp start={(state.val9===1) ? 1 : 1} end={1} duration={2} onEnd={()=> setState({val9:1})}/> : <CountUp start={1} end={1} duration={0} />}              
        <p className='space'> ranked</p>
        <div className='labelcont'>
              living India news channel in punjab
             </div>
            </div>
      </div>
      <div className="App">
      <div className="countnum">
             {isVisible ? <CountUp start={(state.val10===1) ? 1 : 130} end={130} duration={2} onEnd={()=> setState({val10:130})}/> : <CountUp start={1} end={130} duration={0} />} +           
        <p className='space'> rooms</p>
        <div className='labelcont'>
              Fairfield by Marriott Hotel, Amritsar
             </div>
            </div> 
            
      </div>
      
      </div>   
      </div>    
        
      </div>
                        </div>
                        
            )}
        </VisibilitySensor>
        
        </div>
            </section>
            </>
        )
};

export default Number;